<template>
  <div class="trips">
    <lenon-list-page
        title="Completed Trips"
        :columns="columns"
        :rows="trips"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="trip"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-date-picker v-model="startDate" :show-label="false" label="Start Date"/>
              </div>
              <div class="col-md-6">
                <lenon-date-picker v-model="endDate" :show-label="false" label="End Date"/>
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-date-picker v-model="startDate" :show-label="false" class="mr-1" label="Start Date"/>
          <lenon-date-picker v-model="endDate" :show-label="false" label="End Date"/>
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.trip
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #trip_type="{row}">
        <b-badge variant="light-primary">
          {{
            [row.item.trip_type].toString()
                .toUpperCase()
          }}
        </b-badge>
      </template>
      <template #actions="{ row }">
        <lenon-button
            icon-only
            variant="flat-primary"
            :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
            tool-tip-text="View details"
            @onClick="row.toggleDetails"
        />
      </template>
      <template #row-details="{ row }">
        <b-card>
          <div class="row">
            <div class="col-12">
              <strong>Visited Pickup Points</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div v-if="row.item.pickup_points.length" class="d-flex flex-wrap">
                <b-badge variant="light-success" v-for="(item,i) in row.item.pickup_points" :key="i">
                  {{ item }}
                </b-badge>
              </div>
              <b-badge v-else variant="light-success">
                N/A
              </b-badge>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-1">
              <strong>Conductors Onboarded</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <b-badge variant="light-success" v-for="(item,i) in row.item.conductors" :key="i">
                  {{ item }}
                </b-badge>
              </div>
            </div>
          </div>
        </b-card>
      </template>
    </lenon-list-page>
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge, BCard } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'
import LenonDatePicker from '@/lenon/components/LenonDatePicker'

export default {
  name: 'CompletedTrips',
  components: {
    LenonDatePicker,
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    LenonButton,
    BBadge,
    BCollapse,
    BCard
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      tripsModalOpened: false,
      tableLoading: false,
      startDate: null,
      endDate: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'driver',
          label: `Driver`,
        },
        {
          key: 'vehicle',
          label: `Vehicle`,
        },
        {
          key: 'trip_type',
          label: `Trip Type`,
        },
        {
          key: 'date',
          label: `Date`,
        },
        {
          key: 'start_time',
          label: `Start Time`,
        },
        {
          key: 'end_time',
          label: `End Time`,
        },
        {
          key: 'duration',
          label: `Duration(Hours)`,
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ],
    }
  },
  computed: {
    completedSettings() {
      return this.$store.getters['trip/completedSettings']
    },
    trips() {
      return this.$store.getters['trip/completedTrips']
    },
  },
  watch: {
    startDate(val) {
      if (this.startDate && this.endDate) {
        this.fetchTrips()
      }
      this.updateSettings()
    },
    endDate(val) {
      if (this.startDate && this.endDate) {
        this.fetchTrips()
      }
      this.updateSettings()
    }
  },
  methods: {
    updateSettings() {
      this.$store.commit('trip/setCompletedSettings', {
        startDate: this.startDate,
        endDate: this.endDate,
      })
    },
    fetchTrips() {
      this.tableLoading = true
      this.$http.get(`transportation/completed-trips?start_date=${this.startDate}&end_date=${this.endDate}`)
          .then(res => {
            this.$store.commit('trip/setCompletedTrips', res.data)
          })
          .catch(err => {
            this.showError('Failed to load trips')
          })
          .finally(() => {
            this.tableLoading = false
          })
    },
  },
  mounted() {
    this.startDate = this.completedSettings.startDate
    this.endDate = this.completedSettings.endDate
  }
}
</script>
